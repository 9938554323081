<template>
  <article class="contenedor-hijo">
    <div class="container">
      <div class="row justify-content-center justify-content-md-between mb-3 ml-2 ml-sm-0">
        <div class="col-auto px-0">
          <mdb-btn
            flat
            dark-waves
            icon="arrow-left"
            type="button"
            title="Regresar"
            @click="$router.go(-1)"
          >
            Regresar
          </mdb-btn>
        </div>
      </div>
      <li
        v-if="cargando"
        class="mensaje-no-items py-3"
      >
        <LoaderKel />
        <p class="texto mt-2">
          Cargando
        </p>
      </li>
      <div v-else>
        <div class="encabezado-detalles">
          <header class="h4-responsive text-center pt-3">Detalles del pedido</header>
          <div
            class="row mx-0 mt-2"
            v-if="pedido && pedido.length"
          >
            <div class="col-12 col-sm-4">
              <span class="small-block font-titulo">Vendedor</span>
              <p>{{pedido[0].nom_per}} {{pedido[0].ape_per}}</p>
            </div>
            <div class="col-12 col-sm-4">
              <span class="small-block font-titulo">Estado del pedido</span>
              <p
                v-if="pedido && pedido[0].est_ped"
                :class="[asignarColorEstado(pedido[0].est_ped), 'font-weight-bold'] "
              >
                {{ pedido[0].est_ped.slice(0,1).toUpperCase() + pedido[0].est_ped.slice(1) }}
              </p>
              <p
                v-else
                class="text-muted font-italic user-select-none"
              >
                Sin especificar
              </p>
            </div>
            <div class="col-12 col-sm-4">
              <span class="small-block font-titulo">Número de pedido</span>
              <p class="text-capitalize">{{ this.$route.params.id }}</p>
            </div>
            <div class="col-12 col-sm-4">
              <span class="small-block font-titulo">Tipo de documento</span>
              <p class="text-capitalize">{{ pedido[0] && pedido[0].nom_tdoc }}</p>
            </div>
            <div class="col-12 col-sm-4">
              <span class="small-block font-titulo">Tipo de precio</span>
              <p class="text-capitalize">{{ pedido[0] && pedido[0].nom_tpre }}</p>
            </div>
            <div class="col-12 col-sm-4">
              <span class="small-block font-titulo">Condición de pago</span>
              <p class="text-capitalize">{{ pedido[0] && pedido[0].nom_cp }}</p>
            </div>
            <div class="col-12 col-sm-4">
              <span class="small-block font-titulo">Descuento del pedido</span>
              <p class="text-capitalize">{{ pedido[0] && pedido[0].dsc_ped ? pedido[0].dsc_ped  : 'Sin descuento especificado'}}</p>
            </div>
            <div class="col-12 col-sm-4">
              <span class="small-block font-titulo">Fecha del pedido</span>
              <p class="text-capitalize">{{ pedido[0] && pedido[0].fec_ped ? pedido[0].fec_ped : 'Sin fecha especificada' }}</p>
            </div>
            <div class="col-12 col-sm-6">
              <span class="small-block font-titulo">Cliente, Sucursal</span>
              <p>
                <span :class="pedido[0] && !pedido[0].nom_emp.length ? 'text-muted font-italic user-select-none' : 'text-capitalize'">
                  {{ pedido[0] && pedido[0].nom_emp ? pedido[0].nom_emp : 'Cliente no especificado' }},
                </span>
                <span :class="pedido[0] && !pedido[0].nom_suc.length ? 'text-muted font-italic user-select-none' : 'text-capitalize'">
                  {{ pedido[0] && pedido[0].nom_suc ? pedido[0].nom_suc : 'Sucursal no especificada' }}
                </span>
              </p>
            </div>
            <div class="col-12 col-sm-6">
              <span class="small-block font-titulo">Observación</span>
              <p
                :class="{
                  'text-muted font-italic user-select-none': pedido[0] && !pedido[0].obs_ped.length,
                }"
              >
                {{
                  (pedido[0] && pedido[0].obs_ped && pedido[0].obs_ped) ||
                  'Sin especificar'
                }}
              </p>
            </div>
            <div class="col-12">
              <span class="small-block font-titulo">Dirección</span>
              <p class="text-capitalize">
                {{ pedido[0] && pedido[0].dir_suc }},
                {{ pedido[0] && pedido[0].ciu_zon }},
                {{ pedido[0] && pedido[0].est_zon }}.
              </p>
            </div>
          </div>
          <div
            v-else
            class="mensaje-no-items pb-3"
          >
            <font-awesome-icon icon="clipboard" size="5x" class="icono" />
            <p class="texto">Sin detalles del pedido</p>
          </div>
        </div>
        <ul class="table p-notas">
          <li class="encabezado sticky">
            <header class="h4-responsive">Notas de pedido</header>
            <p class="descripcion">
              En lista: {{ notasPedido && notasPedido.length }}
            </p>
            <div class="thead claro">
              <div class="col-12 col-sm-2 col-lg">
                Nombre
                <span class="small-block">Categoría</span>
              </div>
              <div class="col-12 col-sm-2">
                Descripción
                <span class="small-block">Empaque - Código</span>
              </div>
              <div class="col-12 col-sm-2 col-xl-1 pl-xl-1 text-sm-right">
                Cantidad
                <span class="small-block">Unidad</span>
              </div>
              <div class="col-12 col-sm-2 text-sm-right">
                Cantidad Despachada
              </div>
              <div class="col-12 col-sm-2 text-right">
                Costo
                <span class="small-block">IVA</span>
              </div>
              <div class="col-12 col-sm col-lg-2 text-right">
                Total
                <span class="small-block">IVA</span>
              </div>
            </div>
          </li>
          <li
            v-if="notasPedido && !notasPedido.length"
            class="mensaje-no-items pb-3"
          >
            <font-awesome-icon icon="clipboard-list" size="5x" class="icono" />
            <p class="texto">No hay notas de pedidos</p>
          </li>
          <li
            class="contenido"
            v-for="notas in notasPedido"
            :key="notas.id_np"
          >
            <div class="col-12 col-sm-2 col-lg" data-columna="Nombre / Categoría">
              <p>
                {{ notas.nom_pro }}
                <span class="small-block">
                  {{ notas.nom_cat }}
                </span>
              </p>
            </div>
            <div class="col-12 col-sm-2" data-columna="Descripción / Empaque - Código">
              <p>
                {{ notas.des_pre }}
                <span class="small-block">
                  {{ notas.paq_pre }} - {{ notas.cod_pre }}
                </span>
              </p>
            </div>
            <div class="cl-12 col-sm-2 col-xl-1 pl-xl-1 text-sm-right" data-columna="Cantidad / Unidad">
              <p>
                  {{ notas.can_ped_np}}
                  <span class="small-block">
                    {{ notas.uni_paq_pre }}
                  </span>
              </p>
            </div>
            <div class="cl-12 col-sm-2 text-sm-right" data-columna="Cantidad despachada">
              <p>
                  {{ cantidadDes(notas) }}
              </p>
            </div>
            <div class="col-12 col-sm-2 text-sm-right" data-columna="Costo / IVA">
              <p>
                {{notas.pvmp_pre ? notas.pvmp_pre : '0.00'}}
                <span class="small-block">
                  {{ notas.iva_pre ? notas.iva_pre : '0.00' }}
                </span>
              </p>
            </div>
            <div class="col-12 col-sm col-xl-2 text-sm-right" data-columna="Total / IVA">
              <p>
                {{ calculaTotal(notas.can_ped_np, notas.pvmp_pre) }}
                <span class="small-block">
                  {{ calcularIva(notas.can_ped_np, notas.pvmp_pre, notas.iva_pre) }}
                </span>
              </p>
            </div>
          </li>
          <li
            v-if="notasPedido.length"
            class="contenido claro justify-content-sm-end text-sm-right"
          >
            <div class="col-12 d-sm-none" data-columna="Totales"></div>
            <div class="col-12 col-sm-auto" data-columna="Descuento / IVA">
              <div>
                <span class="d-none d-sm-block small-block font-weight-bold">Descuento</span>
                <p>
                  {{calcularDescuento}}
                  <span class="d-sm-none small-block">
                  {{calcularTotalIva}}
                  </span>
                </p>
              </div>
              <div class="d-none d-sm-block">
                <span class="small-block font-weight-bold">Iva</span>
                <p>{{calcularTotalIva}}</p>
              </div>
            </div>
            <div class="col-12 col-sm-auto" data-columna="Subtotal / Total">
              <div>
                <span class="d-none d-sm-block small-block font-weight-bold">Subtotal</span>
                <p>
                  {{calcularSubTotal}}
                  <span class="d-sm-none small-block">
                  {{calcularMontoTotal}}
                  </span>
                </p>
              </div>
              <div class="d-none d-sm-block">
                <span class="small-block font-weight-bold">Total</span>
                <p>{{calcularMontoTotal}}</p>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <AlertaMensaje
      :alertaMensaje="alertaMensaje"
      @cerrar="alertaMensaje.contenido = ''"
    />
  </article>
</template>

<script>
import { apiPost } from '@/funciones/api.js'
import { asignarColorEstado } from '@/funciones/funciones.js'
import { mdbBtn } from 'mdbvue'
import AlertaMensaje from '@/components/AlertaMensaje.vue'
import LoaderKel from '@/components/LoaderKel.vue'
export default {
  name: 'Pedido',
  components: {
    mdbBtn,
    AlertaMensaje,
    LoaderKel
  },
  data () {
    return {
      asignarColorEstado,
      pedido: [],
      notasPedido: [],
      productosPedido: [],
      cargando: false,
      alertaMensaje: {
        contenido: ''
      },
      ivaTotal: 0
    }
  },
  mounted () {
    this.obtenerDetallesPedido()
  },
  computed: {
    calcularSubTotal () {
      let subtotal = 0
      this.notasPedido.forEach((nota) => {
        subtotal += nota.pvmp_pre * nota.can_ped_np
      })
      return subtotal
    },
    calcularDescuento () {
      if (!this.pedido.length) return 0
      return ((parseFloat(this.pedido[0].dsc_ped) / 100) * this.calcularSubTotal).toFixed(2)
    },
    calcularTotalIva () {
      let iva = 0
      this.notasPedido.forEach((nota) => {
        iva += (parseFloat(nota.pvmp_pre || 0) *
          parseFloat(nota.can_ped_np) *
          parseFloat(nota.iva_pre)) /
        100
      })
      return Number((iva).toFixed(2))
    },
    calcularMontoTotal () {
      return (parseFloat(this.calcularSubTotal) - parseFloat(this.calcularDescuento) + parseFloat(this.calcularTotalIva)).toFixed(2) || 0
    }
  },
  methods: {
    async obtenerDetallesPedido () {
      this.cargando = true
      if (!this.$route.params.id) {
        this.$router.push('/pedidos')
        return
      }
      try {
        const { data } = await apiPost(
          { s: 'ped_detM' },
          'ped=' + this.$route.params.id
        )
        if (data) {
          this.pedido = data.ped
          this.productosPedido = data.pre
          this.notasPedido = data.not
        }
        this.cargando = false
      } catch (error) {
        this.alertaMensaje = {
          contenido: 'No se pudo obtener el pedido',
          tipo: 'error'
        }
        this.cargando = false
        this.$router.push('/pedidos')
      }
    },
    calculaTotal (cantidad, precio) {
      return cantidad * precio
    },
    calcularIva (cantidad, precio = 0, iva = 0) {
      return (parseFloat(precio || 0) *
          parseFloat(cantidad) *
          parseFloat(iva)) /
        100
    },
    cantidadDes (notas) {
      return Number(notas.can_ped_np) === Number(notas.can_np) ? 0 : Number(notas.can_ped_np) - Number(notas.can_np)
    }
  }
}
</script>

<style lang="scss" scoped>
.table.p-notas {
  // Define los breakpoints para el responsive de ul.table
  $breakpointToChange: "576px";
  @include ul-table-responsive-at ($breakpointToChange);
}
</style>
